<template>
  <main class="site-main">

    <!--상단 타이틀 영역 s-->
    <div class="top-level-page-area top-level-page-area-05">
      <div class="bms-relaunch-comp-v2">
        <h1 class="page-header">VOD Contents</h1>
        <div class="feature-body">
          <p>BMS에서 주최한 각종 심포지엄 행사 및 제품 관련 교육 동영상 VOD를 제공하는 서비스 입니다.</p>
        </div>
      </div>
    </div>
    <!--상단 타이틀 영역 e-->

    <div class="container">

      <!--Breadcrumb 영역 s-->
      <div class="top-nav-container">
        <nav role="navigator">
          <ul class="breadcrumbs list-unstyled hide-sm">
            <li class="breadcrumb"><a href="#">웨비나 & VOD</a></li>
            <li class="breadcrumb">VOD Contents</li>
          </ul>
        </nav>
      </div>
      <!--Breadcrumb 영역 e-->

      <div class="section">

        <!--Page Title 영역 s-->
        <div class="tit_page_area">
          <h1>VOD Contents</h1>
          <p>BMS에서 주최한 각종 심포지엄 행사 및 제품 관련 교육 동영상 VOD를 제공하는 서비스 입니다.</p>
          <!--<div class="tit_right_icon_area">
              <a href="./product_brochure_listview.html">
                  <img src="@/assets/images/icon_listview.png" alt="">
              </a>
          </div>-->
        </div>
        <!--<div class="update_area">
            <span>Last Update</span>2021.04.01
        </div>-->
        <!--Page Title 영역 e-->

        <!--본문 영역 s-->
        <content-area
            v-bind="board"
            file-name="BMSON-심포지엄_VOD"
            :bookmark-id="$route.params.symposiumVodNo"/>
        <!--본문 영역 e-->

        <!--list view 버튼-->
        <div class="btn_area_right btn_prev">
          <router-link to='../' append>
            <button>이전 보기</button>
          </router-link>
        </div>

      </div>
    </div>
  </main>


</template>

<script>
import ContentArea from "@/components/common/ContentArea";

export default {
  name: "SymposiumVODView",
  components: {ContentArea},
  created() {
    this.initBoard();
  },
  data() {
    return {
      board: {
        title: ''
      },
    }
  },
  methods: {
    initBoard() {
      this.$store.dispatch('initBoard', {
        id: this.$route.params.symposiumVodNo,
        type: 'symposium-vod'
      }).then(board => {
        this.board = board
      })
    }
  },
}
</script>

<style scoped>

</style>
